var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "back-top",
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "scroll-to",
              rawName: "v-scroll-to",
              value: "body",
              expression: "'body'",
            },
          ],
          staticStyle: { color: "#8e8e8e" },
        },
        [
          _c("svg-icon", {
            attrs: { "icon-class": "backtop", width: 30, height: 30 },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }