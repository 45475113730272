var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-compoment" },
    [
      _c(
        "van-sticky",
        [
          _c("van-nav-bar", {
            staticClass: "nav",
            attrs: {
              title: _vm.title,
              "left-text": _vm.leftText,
              "left-arrow": _vm.leftArrow,
            },
            on: { "click-left": _vm.onClickLeft },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [_vm._t("default")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }