var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-image",
        _vm._b(
          {
            staticStyle: { display: "block" },
            attrs: { "lazy-load": "" },
            scopedSlots: _vm._u([
              {
                key: "loading",
                fn: function () {
                  return [
                    _c("van-loading", {
                      attrs: { type: "spinner", size: "20" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "van-image",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }