// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nav-compoment .van-nav-bar .van-icon {\n  color: #333;\n}\n.nav-compoment .van-nav-bar .van-nav-bar__text {\n  color: #333;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#f1574f",
	"black": "#333",
	"red": "#ea625b",
	"green": "#38ca73",
	"bgGradient": "linear-gradient(90deg, #0af, #0085ff)"
};
module.exports = exports;
