import request from '@/utils/request'

export function getAddress() {
  return request({
    url: '/api/address/list',
    method: 'get'
  })
}

export function addAddress(data) {
  return request({
    url: '/api/address/save',
    method: 'post',
    data
  })
}

export function updateAddress(data) {
  return request({
    url: '/api/address/update',
    method: 'post',
    data
  })
}

export function deleteAddress(data) {
  return request({
    url: '/api/address/delete',
    method: 'post',
    data
  })
}
