import {login, logout, getInfo,mpWeChatLogin} from '@/api/user'
import {getToken, setToken, removeToken} from '@/utils/auth'
import {setCache, getCache} from "@/utils/cache";

const state = {
  token: getToken(),
  id: '',
  name: '',
  userInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info
    state.id = info.id
    state.name = info.nickname
  }
}

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { mobile, password, qrCode ,uuid} = userInfo
    return new Promise((resolve, reject) => {
 
      login({ mobile, password, qrCode ,uuid})
        .then(res => {
          const { token ,user} = res.data
          setCache('CacheToken', res.data, true);
          commit('SET_TOKEN', token)
          commit('SET_USER_INFO', user)
          setToken(token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 登出
  logout({commit}) {
    return new Promise((resolve, reject) => {

      commit('SET_TOKEN', '')
      removeToken()
      setCache('CacheToken', null, true);
      resolve()

    })
  },

  // 获取基本用户信息
  getInfo({commit, state}) {
    return new Promise((resolve, reject) => {
      const userInfo = getCache('CacheToken', {
        isSessionStorage: true
      }, {})
        ?.user || {};
      commit('SET_USER_INFO', userInfo)
      resolve(userInfo)
      // getInfo(state.token)   .then(res => {     const data = res.data     if
      // (!data) {       reject(new Error('获取基本信息失败，请重新登录'))     }
      // commit('SET_USER_INFO', data.info) resolve(data)   })   .catch(error => {
      // reject(error)   })
    })
  },
  // 重置token
  resetToken({commit}) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  // 微信登录
  mpWeChatLogin( {commit},code ) {
    return new Promise((resolve, reject) => {
   
      mpWeChatLogin(code).then(res => {
          const { token } = res.data
          setCache('CacheToken', res.data, true);
          commit('SET_TOKEN', token)
          commit('SET_USER_INFO', res.data)
          setToken(token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced : true,
  state,
  mutations,
  actions
}
